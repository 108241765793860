import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import map from 'lodash/map'
import join from 'lodash/join'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

// Base Page
import BasePage from '@/pages/Base'

// Services
import MembersPartnerServices from '@/services/MembersPartner'

@Component({})
export default class MembersPartner extends BasePage {
  constructor() {
    super()
  }

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Relation',
      align: 'left',
      sortable: true,
      value: 'relation'
    },
    {
      text: 'Relation Date',
      align: 'left',
      sortable: true,
      value: 'relation-date'
    },
    {
      text: 'Orders',
      align: 'center',
      sortable: false,
      value: 'email'
    },
    {
      text: 'Memberships',
      align: 'left',
      sortable: false,
      value: 'memberships'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: '-created_at',
    page: 1,
    rowsPerPage: 50,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  totalPartner: string = ''

  partnerName: string = ''
  memberTotal: number = 0
  orderTotal: number = 0
  selectedMember: number = 0
  showDeleteModal: boolean = false
  membersDialog: boolean = false
  membersDialogData: any = {}
  usersItem: any = []
  searchUserDebounce: Function
  relationType: any = [
    {
      name: 'Referral',
      group: 1
    },
    {
      name: 'Sub Agent',
      group: 2
    },
    {
      name: 'Partner',
      group: 3
    }
  ]


  @Watch('tablePagination', { deep: true })
  @Watch('searchInput', { deep: true })
  @Watch('membersDialogData.keywordUsers')
  async onChanged() {
    this.$vuetify.goTo(0)
    this.searchUserDebounce = debounce(this.getUsersItem, 500)
    this.searchUserDebounce()
    this.getMembers()
  }

  async mounted() {
    // this.getMembers()
  }

  async getMembers() {
    const opts: any = {
      params: {
        page: this.tablePagination.page,
        limit: this.tablePagination.rowsPerPage,
        sort: '-created_at'
      }
    }

    if (this.searchInput != '') {
      opts.params = {
        ...opts.params,
        'filter[name][like]': this.searchInput
      }
    } else {
      var textFilter = 'filter[name][like]'
      delete opts.params[textFilter]
    }


    this.tableLoading = true
    try {
      const responseUser = await MembersPartnerServices.getOneUser(this.$route.params.id)
      const responseDataUser = responseUser.data.data

      this.partnerName = responseDataUser.attributes.name

      // const responseMember = await MembersPartnerServices.getMembers(this.$route.params.id, opts)
      // const responseDataMember = responseMember.data.data
      // const responseMetaMember = responseMember.data.meta
      // console.log('api member lawas', responseMember)

      const responseChild = await MembersPartnerServices.getRelations(this.$route.params.id)
      const responseDataChild = responseChild.data.included.childs

      const responseAgent = await MembersPartnerServices.getReferralAgent(this.$route.params.id)
      const responseDataAgent = responseAgent.data.included.referralAgent

      const responsePartner = await MembersPartnerServices.getReferralPartner(this.$route.params.id)
      const responseDataPartner = responsePartner.data.included.referralPartner

      this.tableItems = []
      this.orderTotal = 0

      // // api lawas
      // forEach(responseDataMember, dataMember => {
      //   const member = dataMember.attributes
      //   this.orderTotal = this.orderTotal + member.number_order
      //   this.tableItems.push(member)
      // })

      // api child
      forEach(responseDataChild, dataChild => {
        const child = dataChild.attributes
        child.group = 1
        this.orderTotal = this.orderTotal + child.number_order
        this.tableItems.push(child)
      })

      // api agent
      forEach(responseDataAgent, dataAgent => {
        const agent = dataAgent.attributes
        agent.group = 2
        this.orderTotal = this.orderTotal + agent.number_order
        this.tableItems.push(agent)
      })

      // api partner
      forEach(responseDataPartner, dataPartner => {
        const partner = dataPartner.attributes
        partner.group = 3
        this.orderTotal = this.orderTotal + partner.number_order
        this.tableItems.push(partner)
      })

      this.tableTotalItems = this.tableItems.length
      this.memberTotal = this.tableItems.length
      this.tableLoading = false

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getUsersItem() {
    try {
      // if (isEmpty(this.supplierDialogData.client)) {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.membersDialogData.keywordUsers)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.membersDialogData.keywordUsers
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await MembersPartnerServices.getUsersChild(this.$route.params.id, opts)

      const dataUser = response.data.data

      for (const userList of dataUser) {
        const user: any = {
          name: userList.attributes.name + ' (' + userList.attributes.email + ')',
          id: userList.attributes.id
        }
        this.usersItem.push(user)
      }
      // } 
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async addMembersPartner() {
    try {
      const validationResponse = await this.$validator.validateAll('membersPartnerDialogScope')
      if (validationResponse) {

        this.membersDialog = false
        this.showLoading({ text: 'Creating...' })

        const dialogData = {
          user_id: this.$route.params.id,
          related_id: this.membersDialogData.membersPartner,
          group: this.membersDialogData.group
        }
        await MembersPartnerServices.setRelation(dialogData)

        this.closeLoading()
        this.showSnackbar({
          text: 'Member(s) Created Successfully!',
          color: 'green',
          timeout: 2500
        })
        this.getMembers()
      } else {
        this.showSnackbar({
          text: 'Please check all filed(s) requirements',
          color: 'red',
          timeout: 3500
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async deleteMembersPartner() {
    try {
      this.membersDialog = false
      this.showLoading({ text: 'Deleting...' })

      const memberId = this.selectedMember


      await MembersPartnerServices.deleteRelation(memberId)
      this.showDeleteModal = false
      this.closeLoading()
      this.showSnackbar({
        text: 'Member(s) Deleted Successfully!',
        color: 'green',
        timeout: 2500
      })
      this.getMembers()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.showDeleteModal = false
      this.closeLoading()
    }
  }

  openCreateDialog() {
    this.membersDialog = true
    this.membersDialogData = {
      keywordUsers: null,
      membersPartner: null,
      loadingUsers: false,
      group: null
    }
  }

  openDeleteModal(props) {
    this.selectedMember = props.item.pivot.id
    this.showDeleteModal = true
  }
}
