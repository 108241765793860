import BaseService from '@/services/BaseService'

class MembersPartnerServices extends BaseService {
  constructor() {
    super()
  }

  async getOneUser(id: any, params = {}) {
    this.endPoint = 'api/v2/users'
    return this.getOne(id, params)
  }

  async getMembers(id: any, params = {}) {
    this.endPoint = 'api/v2/users/parentables'
    return this.getOne(id, params)
  }

  async setMembersPartner(id: any, data: any) {
    this.endPoint = 'api/v2/users'
    return this.putOne(id, data)
  }

  async getUsersChild(id: any, opts = {}) {
    this.endPoint = 'api/v2/users/childables/' + id
    return await this.get(opts)
  }

  async getRelations(id: any) {
    this.endPoint = `api/v2/users/${id}?include=childs`
    return await this.get(id)
  }

  async getReferralAgent(id: any) {
    this.endPoint = `api/v2/users/${id}?include=referralAgent`
    return this.get(id)
  }

  async getReferralPartner(id: any) {
    this.endPoint = `api/v2/users/${id}?include=referralPartner`
    return this.get(id)
  }

  async setRelation(data: any) {
    this.endPoint = 'api/v2/users/user-relations'
    return await this.post(data)
  }

  async deleteRelation(id: any) {
    this.endPoint = `api/v2/users/user-relations`
    return this.delete(id)
  }

}
export default new MembersPartnerServices()